import React, { useState, useEffect } from "react";

import { useLoaderData, useNavigate } from "react-router-dom";
import Booking from "../models/bookingModel";
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from "date-fns";
import { Image, Icon, Label } from "semantic-ui-react";
import { DayPicker } from "react-day-picker";
import BookingCreateModal, {
  ModalForm,
  FriendOption,
} from "./BookingCreateModal";
import "react-day-picker/dist/style.css";
import styles from "./bookingList.module.css";
import {
  getBookingList as getBookingListFromServer,
  cancelBooking,
} from "../api/bookingAPI";
import { getUserList } from "../api/bookingAPI";
import { BookingUser } from "../models/bookingModel";

const css = `
  .rdp-month {
    margin: auto !important;
  }
  .rdp {
    --rdp-accent-color: rgb(221,132,112);
    --rdp-cell-size: 45px;
    border: 1px solid #ccc;
    background: white;
  } 
  button.rdp-day_selected.rdp-day {
    color: black;
  }
  .booked::before {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 6px;
    height: 6px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #a330d0;
  }

`;

const dateFormat = "yyyy-MM-dd";
const defaultModalForm = {
  id: undefined,
  lineId: "",
  picture: "",
  description: "",
  date: format(new Date(), dateFormat),
  startTime: format(new Date(), "HH:00:00"),
  endTime: format(new Date(), "HH:00:00"),
  comment: "",
  realName: "",
};

type LineIdUserId = {
  [lineId: string]: string;
};

const createDateRangeBookingDateCache = (date) => {
  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = endOfMonth(date);

  const daysArray = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });

  return daysArray.map((day) => format(day, "yyyy-MM-dd"));
};

const defaultBookingDateCache = createDateRangeBookingDateCache(new Date());
const BookingList = () => {
  const bookingListFromLoader = useLoaderData() as Booking[];

  const date = new Date();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = React.useState<Date>(date);
  const [modalForm, setModalForm] = useState<ModalForm>(defaultModalForm);
  const [bookingList, setBookingList] = useState<Booking[]>(
    bookingListFromLoader
  );
  const [friendOptions, setFriendOptions] = useState<FriendOption[]>([]);
  const [lineIdUserIdMap, setLineIdUserIdMap] = useState<LineIdUserId>({});
  const [bookingDateCache, setBookingDateCache] = useState<string[]>(
    defaultBookingDateCache
  );

  const updateBookingList = async (date) => {
    if (bookingDateCache.includes(date)) {
      return;
    }

    const newBookingList = await getBookingListFromServer(date, date);
    bookingDateCache.push(date);
    setBookingDateCache(bookingDateCache);
    setBookingList(bookingList.concat(newBookingList));
  };
  const handleClose = () => setOpen(false);
  const handleClick = ({
    id,
    line_id,
    booking_time,
    end_time,
    picture,
    description,
    comment,
    real_name,
  }) => {
    const bookingTime = new Date(booking_time);
    const endTime = new Date(end_time ? end_time : booking_time);
    setModalForm({
      id,
      picture,
      lineId: line_id,
      description,
      date: format(bookingTime, dateFormat),
      startTime: format(bookingTime, "HH:mm:ss"),
      endTime: format(endTime, "HH:mm:ss"),
      comment,
      realName: real_name,
    });
    setOpen(true);
  };
  const booked: Date[] = bookingList.map((booking) => {
    return new Date(booking.booking_time);
  });

  const modifiers = {
    booked: booked,
  };
  const handleChangeDate = (date) => {
    if (date) {
      setSelected(date);
    }
  };
  const handleChangeMonth = async (month) => {
    const start: string = format(startOfMonth(month), dateFormat);
    const end: string = format(endOfMonth(month), dateFormat);
    const newBookingList = await getBookingListFromServer(start, end);
    setBookingDateCache(createDateRangeBookingDateCache(startOfMonth(month)));
    setBookingList(newBookingList);
  };

  const handleRefresh = async () => {
    const start: string = format(startOfMonth(selected), dateFormat);
    const end: string = format(endOfMonth(selected), dateFormat);
    const newBookingList = await getBookingListFromServer(start, end);
    setBookingList(newBookingList);
  };
  const handleCancel = async (id) => {
    await cancelBooking(id);
  };
  useEffect(() => {
    async function updateUserList() {
      const bookingUser = await getUserList();
      const userList: FriendOption[] = bookingUser.map((user: BookingUser) => {
        return {
          key: user.lineId,
          text: user.name,
          value: user.lineId,
          image: { avatar: true, src: user.picture },
        };
      });
      const tmpLineIdUserIdMap = {};
      bookingUser.forEach((user: BookingUser) => {
        tmpLineIdUserIdMap[user.lineId] = user.id;
      });

      setLineIdUserIdMap(tmpLineIdUserIdMap);
      setFriendOptions(userList);
    }
    updateUserList();
  }, []);
  return (
    <>
      <style>{css}</style>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={handleChangeDate}
        defaultMonth={date}
        onMonthChange={handleChangeMonth}
        modifiers={modifiers}
        modifiersClassNames={{ booked: "booked" }}
        ISOWeek
      />
      <div className={styles.list}>
        {bookingList
          .filter(
            (booking) =>
              format(new Date(booking.booking_time), dateFormat) ===
              format(selected, dateFormat)
          )
          .sort((b1: Booking, b2: Booking) => {
            return (
              new Date(b1.booking_time).getTime() -
              new Date(b2.booking_time).getTime()
            );
          })
          .map((booking) => {
            const { id, booking_time, name, picture, description, line_id } =
              booking;
            const dateObj = new Date(booking_time);
            const time = format(dateObj, "HH:mm");
            const userId = lineIdUserIdMap[line_id];
            const isNew = booking.is_new;
            return (
              <div
                className={styles.detail}
                key={id}
                onClick={() => handleClick(booking)}
              >
                {isNew && (
                  <Label as="a" color="red" corner="right">
                    <span className={styles.new}>New</span>
                  </Label>
                )}
                <div className={styles.picContainer}>
                  <Image
                    src={picture}
                    className={styles.pic}
                    circular
                    size="mini"
                    onClick={(e) => {
                      if (userId) {
                        navigate(`/users/${userId}`);
                      }
                      e.stopPropagation();
                    }}
                  />
                  <div className={styles.name}>{name}</div>
                </div>
                <div>{description}</div>
                <div className={styles.time}>{time}</div>
              </div>
            );
          })}
      </div>
      <div
        className={styles.plusContainer}
        onClick={() =>
          handleClick({
            id: "",
            line_id: "",
            booking_time: format(selected, "yyyy-MM-dd HH:00:00"),
            picture: "",
            description: "",
            real_name: "",
            end_time: format(selected, "yyyy-MM-dd HH:00:00"),
            comment: "",
          })
        }
      >
        <Icon name="plus" size="big" color="violet" className={styles.plus} />
      </div>
      <BookingCreateModal
        onCancel={handleCancel}
        onClose={handleClose}
        onRefresh={handleRefresh}
        updateBookingList={updateBookingList}
        open={open}
        modalForm={modalForm}
        friendOptions={friendOptions}
        bookingList={bookingList}
      />
    </>
  );
};

export default BookingList;
