import { useState, useContext } from "react";
import {
  Grid,
  Icon,
  Image,
  Card,
  Segment,
  Statistic,
  Button,
} from "semantic-ui-react";
import icon from "../assets/user.png";
import User from "../models/userModel";
import { useLoaderData, useNavigate, Link } from "react-router-dom";
import styles from "./userDetail.module.css";
import BalanceHistory from "../models/balanceHistoryModel";
import BalanceUpdateModal, {
  defaultModalForm,
  ModalForm,
  ModalContent,
} from "./BalanceUpdateModal";
import DeleteModal from "./DeleteModal";
import { deleteBalanceHistory } from "../api/userAPI";
import { PermissionContext, usePermission } from "./PermissionProvider";

function refinedHistoryRecords(balanceHistory, balance): [] {
  return balanceHistory.reduce((acc, current) => {
    const amount = (current.type !== "DEPOSIT" ? -1 : 1) * ~~current.amount;
    if (acc[current.transaction_id] === undefined) {
      acc[current.transaction_id] = {
        time: new Intl.DateTimeFormat("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(~~current.order_date * 1000)),
        description: current.type === "DEPOSIT" ? "儲值" : current.description,
        amount,
        type: current.type,
        currentTotal: balance,
      };
    } else {
      const totalAmount = acc[current.transaction_id].amount + amount;
      acc[current.transaction_id] = {
        ...acc[current.transaction_id],
        amount: totalAmount,
      };
    }
    balance -= amount;
    return acc;
  }, {});
}

function UserDetail() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState<{
    userId?: string;
    name?: string;
    transactionId?: string;
    amount?: number;
    type?: string;
  }>({});
  const permission = usePermission();

  const user = useLoaderData() as User & { balance_history: BalanceHistory[] };
  const balanceHistory = user.balance_history;
  const records = refinedHistoryRecords(balanceHistory, user.balance) as [];
  const navigate = useNavigate();
  return (
    <Grid columns="equal" inverted padded>
      <Grid.Row centered>
        <Image
          src={user.picture || icon}
          size="tiny"
          circular
          className={styles.image}
        />
      </Grid.Row>
      <Grid className={styles.summaryInfo} divided padded>
        <Grid.Row centered>
          <label className={styles.label}>儲值餘額</label>
        </Grid.Row>
        <Grid.Row textAlign="center" className={styles.gridRow}>
          <Grid.Column>
            <div className={styles.balance}>{`$ ${user.balance}`}</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {Object.keys(records).map((transactionId) => {
        return (
          <Grid.Row key={transactionId}>
            <Grid.Column>
              <Segment.Group>
                <Segment raised className={styles.record}>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {records[transactionId].description}
                    </Statistic.Value>
                  </Statistic>
                  <Statistic
                    color={records[transactionId].amount > 0 ? "red" : "green"}
                    size="mini"
                  >
                    <Statistic.Value>
                      {records[transactionId].type === "DEPOSIT"
                        ? "+" + records[transactionId].amount
                        : records[transactionId].amount}
                    </Statistic.Value>
                    <Statistic.Label>
                      {records[transactionId].time}
                    </Statistic.Label>
                  </Statistic>
                </Segment>
                {user.admin && (
                  <Segment raised className={styles.record}>
                    {/* <Button>編輯</Button> */}
                    <Button
                      onClick={() => {
                        setDeleteModalContent({
                          userId: user.id,
                          name: user.name,
                          transactionId,
                          amount: records[transactionId].amount,
                          type: records[transactionId].type,
                        });
                        setDeleteModalOpen(true);
                      }}
                    >
                      刪除
                    </Button>
                  </Segment>
                )}
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        );
      })}
      {Object.keys(records).length === 0 && (
        <Grid.Row>
          <Grid.Column>
            <Segment raised className={styles.record}>
              <span>快來預約唷！</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      )}
      {user.admin && (
        <>
          <Grid.Row>
            <Grid.Column>
              <Link to={`/users`}>
                <Card className={styles.card} centered>
                  <Card.Content className="center aligned">
                    <Icon name="address book" size="big" />
                  </Card.Content>
                  <Card.Content className="center aligned" extra>
                    會員列表
                  </Card.Content>
                </Card>
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Link to={`/bookings`}>
                <Card className={styles.card} centered>
                  <Card.Content className="center aligned">
                    <Icon name="calendar alternate" size="big" />
                  </Card.Content>
                  <Card.Content className="center aligned" extra>
                    預約列表
                  </Card.Content>
                </Card>
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Link to={`/admin`}>
                <Card className={styles.card} centered>
                  <Card.Content className="center aligned">
                    <Icon name="sitemap" size="big" />
                  </Card.Content>
                  <Card.Content className="center aligned" extra>
                    額外功能
                  </Card.Content>
                </Card>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <DeleteModal
        open={deleteModalOpen}
        content={
          <div>
            是否刪除 {deleteModalContent.name} 的 {deleteModalContent.amount}{" "}
            {deleteModalContent.type === "DEPOSIT" ? "儲值" : "消費"} 紀錄
          </div>
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onSubmit={async () => {
          try {
            await deleteBalanceHistory(
              deleteModalContent.userId!,
              deleteModalContent.transactionId!
            );
            navigate(0);
            setDeleteModalOpen(false);
          } catch (e) {
            console.error(e);
          }
        }}
      />
      {/* <BalanceUpdateModal
        onClose={handleClose}
        open={open}
        modalContent={modalContent}
        error={error}
        modalForm={modalForm}
        onChange={handleChange}
        onSubmit={handleSubmit}
        load={load}
        submitFail={submitFail}
        defaultDateTime={defaultModalForm.order_date}
        showDescription={showDescription}
      /> */}
    </Grid>
  );
}

export default UserDetail;
